earlycareer.layout.buttonToggle = (function() {
    "use strict";

    let buttonToggleByInputName = {};

    function init() {
        loadAll();
    }

    function reset() {
        for (const inputName in buttonToggleByInputName) {
            buttonToggleByInputName[inputName].reset();
        }

        buttonToggleByInputName = {};
    }

    function loadAll() {
        document.querySelectorAll('.btn-early-toggle').forEach(function (domButton) {
            add(domButton);
        });
    }

    function getForInputName(inputName) {
        if (buttonToggleByInputName.hasOwnProperty(inputName) === true) {
            return buttonToggleByInputName[inputName];
        }

        return null;
    }

    function add(domButton) {
        let input     = domButton.querySelector('input');
        let inputName = input.name;

        let buttonToggle;
        if (buttonToggleByInputName.hasOwnProperty(inputName) === true) {
            buttonToggle = buttonToggleByInputName[inputName];
        } else {
            buttonToggle = new ButtonToggle();
            buttonToggleByInputName[inputName] = buttonToggle;
        }

        buttonToggle.addButton(domButton);
    }

    class ButtonToggle {
        constructor() {
            this.buttonList = {};
            this.buttonClickCallbacksList = [];
        }

        addButton(domButton) {
            let input = domButton.querySelector('input');
            this.buttonList[input.value] = domButton;

            domButton.addEventListener('click', this.buttonClick.bind(this));
        }

        addCallback(callback) {
            this.buttonClickCallbacksList.push(callback);
        }

        reset() {
            for (const inputValue in this.buttonList) {
                const domButton = this.buttonList[inputValue];
                domButton.removeEventListener('click', this.buttonClick.bind(this));
            }
        }

        buttonClick(event) {
            event.preventDefault();

            let clickedDomButton = event.target;
            if (clickedDomButton.classList.contains('.btn-early-toggle') === false) {
                clickedDomButton = clickedDomButton.closest('.btn-early-toggle');
            }

            const clickedDomInput   = clickedDomButton.querySelector('input');
            const clickedInputValue = clickedDomInput.value;
            const isRadio           = (clickedDomInput.type === 'radio');

            for (const inputValue in this.buttonList) {
                const domButton = this.buttonList[inputValue];

                if (inputValue === clickedInputValue && domButton.dataset.toggleStatus === 'on') {
                    continue;
                }

                if (inputValue === clickedInputValue) {
                    this.toggleStatus(domButton);
                    this.toggleChecked(domButton);
                } else if (domButton.dataset.toggleStatus === 'on') {
                    this.toggleStatus(domButton);

                    //if radio, do checked on a item remove checked from others
                    if (isRadio === false) {
                        this.toggleChecked(domButton);
                    }
                }
            }

            for (const callback of this.buttonClickCallbacksList) {
                if (typeof callback === 'function') {
                    callback(this);
                }
            };
        }

        toggleStatus(domButton) {
            let currentStatus = domButton.dataset.toggleStatus,
                newStatus     = 'on';
    
            if (currentStatus === 'on') {
                newStatus = 'off';
            }
    
            domButton.dataset.toggleStatus = newStatus;
        }

        toggleChecked(domButton) {
            const domInput = domButton.querySelector('input');

            if (domInput.checked === true) {
                domInput.checked = false;
            } else {
                domInput.checked = true;
            }
        }
    }

    return {
        init: init,
        add: add,
        reset: reset,
        loadAll: loadAll,
        getForInputName: getForInputName
    }
})();
