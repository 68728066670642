(function() {
    "use strict";

    let domXSMenu,
        domXSMenuContent;

    function init() {
        domXSMenu        = document.querySelector('#headerXSMenu');
        domXSMenuContent = domXSMenu.querySelector('#headerXSMenuContent');

        document.querySelectorAll('.ec-js_header_menu_xs').forEach(function (domItem) {
            domItem.addEventListener('click', openXSMenu);
        });

        let domXSMenuCloseBtn = domXSMenu.querySelector('#headerXSMenuCloseBtn');
        domXSMenuCloseBtn.addEventListener('click', closeXSMenu);

        let domXSMenuBG = domXSMenu.querySelector('#headerXSMenuBG');
        domXSMenuBG.addEventListener('click', closeXSMenu);

        domXSMenu.querySelectorAll('.menu-dropdown').forEach(function (domMenuItem) {
            let domMenuItemA = domMenuItem.querySelector('a');

            domMenuItemA.addEventListener('click', menuXSToggleSubMenu);
        });

        domXSMenu.querySelectorAll('.dropdown-submenu').forEach(function (domMenuItem) {
            let domMenuItemA = domMenuItem.querySelector('a');

            domMenuItemA.addEventListener('click', menuXSToggleSubMenu);
        });
    }

    function openXSMenu(e) {
        let domItem = e.target;

        //For case like user menu when we click on img tag
        if (domItem.classList.contains('ec-js_header_menu_xs') === false) {
            domItem = domItem.closest('.ec-js_header_menu_xs');
        }

        if (domXSMenu.classList.contains('header__xs__menu--show') === true) {
            closeXSMenu();
        }

        let title = domItem.dataset.title;
        domXSMenu.querySelector('#headerXSMenuTitle').innerHTML = title;

        let position = domItem.dataset.position || 'left';
        domXSMenuContent.classList.remove(
            'header__xs__menu__ctn--left',
            'header__xs__menu__ctn--right'
        );
        domXSMenuContent.classList.add('header__xs__menu__ctn--'+position);

        domXSMenu.classList.add('header__xs__menu--show');

        let menuID = domItem.dataset.menuId;
        domXSMenuContent
            .querySelector('.ec-js_header_menu_xs_content[data-menu-id="'+menuID+'"')
            .classList
            .remove('hidden')
        ;
    }
    
    function closeXSMenu() {
        domXSMenu.classList.remove('header__xs__menu--show');

        domXSMenu
            .querySelector('.ec-js_header_menu_xs_content:not(.hidden)')
            .classList
            .add('hidden')
        ;
    }

    function menuXSToggleSubMenu(e) {
        let domMenuItemA = e.target;

        domMenuItemA.classList.toggle('dropdown--open');
        domMenuItemA.parentNode.querySelector('.dropdown-menu').classList.toggle('dropdown--open');
    }

    return {
        init: init
    }
})().init();
